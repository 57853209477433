import * as React from "react";
import StorefrontCard from "./Cards/StorefrontCards";
import emailjs from "@emailjs/browser";

const IntegraShop = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const modalRef = React.useRef(null);
  const [currentOrder, setCurrentOrder] = React.useState(0);
  const [mail, setMail] = React.useState("");

  const orderData = [
    {
      title: "Apollo 10k leads",
      images: ["/black_pink_db.png", "/apollo_10k.png"],
      price: 4999,
      link: "https://drive.google.com/file/d/1lYUmOhclW2YCooaxAVsu8asNbL8LhB3p/view?usp=drive_link",
      description:
        "The Apollo Leads database, acquired by Synapsed Infotech, is a robust spreadsheet featuring <span style='font-weight: bold; color: #AC3CA4;'>10k entries</span>, each meticulously categorised for easy access and utility. It includes IT industry contacts, fashion sector leads, and spans multiple domains. Key data points include business profiles, contact details, and geographic distribution. This comprehensive dataset offers industry insights, company size metrics, and sector analysis, making it an invaluable resource for targeted marketing and business development efforts. For any queries, please contact Synapsed Infotech at <span style='font-weight: bold; color: #AC3CA4;'>synapsedinfotech@gmail.com.</span>",
      features: [
        "IT Industry Contacts",
        "Fashion Sector Leads",
        "Comprehensive Data",
        "Multi-Domain Coverage",
        "Business Profiles",
        "Contact Details",
        "Industry Insights",
        "Geographic Distribution",
        "Company Size",
        "Sector Analysis",
      ],
    },
    {
      title: "Comprehensive healthcare Data ",
      images: ["/comprehensive_health.png", "/comprehensive_health_sheet.png"],
      price: 2999,
      link: "https://drive.google.com/drive/folders/1UgyIHnnhiHP3Mw5LJ1z_iwTkAE8h38um?usp=drive_link",
      description:
        "The Comprehensive Healthcare Database, acquired by Synapsed Infotech, is an extensive collection featuring over <span style='font-weight: bold; color: #AC3CA4;'>200,000 entries</span> meticulously organized for easy access and utility. Key features include hospital listings, nurse registries, and a comprehensive index of clinics and medical facilities. It also covers specialty services, healthcare contacts, and regional coverage. Detailed entries provide service categories, facility ratings, and operational details, making it an invaluable resource for healthcare professionals, service providers, and researchers. For any queries, please contact Synapsed Infotech at <span style='font-weight: bold; color: #AC3CA4;'>synapsedinfotech@gmail.com.</span>",
      features: [
        "Hospitals Listing",
        "Nurse Registries",
        "Clinics Index",
        "Medical Facilities",
        "Specialty Services",
        "Healthcare Contacts",
        "Regional Coverage",
        "Service Categories",
        "Facility Ratings",
        "Operational Details",
      ],
    },
    {
      title: "Comprehensive B2B Data",
      images: ["/comprehensive_b2b.png", "/comprehensive_b2b_sheet.png"],
      price: 5999,
      link: "https://drive.google.com/drive/folders/1bhN6MUBe1iNBaIGufNGdY0PkIy4VkGmU?usp=drive_link",
      description:
        "The B2B Database (India), acquired by Synapsed Infotech, is an extensive resource featuring over <span style='font-weight: bold; color: #AC3CA4'>200,000 entries</span>, meticulously organized for seamless access and utility. Key features include comprehensive company directories and industry categories, with city-specific listings for targeted outreach. The database provides detailed financial summaries, corporate contacts, and indexes of various industries such as manufacturing, textiles, and jewelry. It also covers grocery markets, wholesale traders, and the plastic industry. Additional insights include business rankings, SME overviews, trade volumes, and market analysis, making it an essential tool for business networking and development. For any queries, please contact Synapsed Infotech at <span style='font-weight: bold; color: #AC3CA4;'>synapsedinfotech@gmail.com.</span>",
      features: [
        "Company Directories",
        "Industry Categories",
        "City-Specific Listings",
        "Financial Summaries",
        "Corporate Contacts",
        "Manufacturing Index",
        "Textile Businesses",
        "Jewellery Crafters",
        "Grocery Markets",
        "Wholesale Traders",
        "Plastic Industry",
        "Business Rankings",
        "SME Overviews",
        "Trade Volumes",
        "Market Analysis",
        "B2B Networks",
        "Corporate Profiles",
      ],
    },
    {
      title: "Student Database",
      images: ["/extensive_student.png", "student_db_sheet.png"],
      price: 3999,
      link: "https://drive.google.com/drive/folders/1wapk3_o27b4CGazFhJ8xtOe7k65gfjSg?usp=drive_link",
      description:
        "The Student Database, acquired by Synapsed Infotech, is an extensive collection featuring over <span style='font-weight: bold; color: #AC3CA4'>20 million entries</span> , meticulously organized for easy access and utility. Key features include yearly enrollments and state-wise data, along with detailed major and minor specializations. The database provides comprehensive university profiles, admission trends, and graduation outcomes. It also includes scholarship details, performance metrics, and extracurricular activities, offering valuable demographic insights. This robust dataset is an essential tool for educational institutions, researchers, and policy makers. For any queries, please contact Synapsed Infotech at <span style='font-weight: bold; color: #AC3CA4'>synapsedinfotech@gmail.com.",
      features: [
        "Yearly Enrollments",
        "State-Wise Data",
        "Major/Minor Specializations",
        "University Profiles",
        "Admission Trends",
        "Graduation Outcomes",
        "Scholarship Details",
        "Performance Metrics",
        "Extracurriculars",
        "Demographic Insights",
      ],
    },
  ];

  React.useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollIntoView();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalRef.current, isModalOpen]);

  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  });

  const onOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://synapsed.onrender.com/create-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: orderData[currentOrder].price,
            currency: "INR",
          }),
        }
      );

      console.log(response);

      if (response.status !== 500) {
        const data = await response.json();
        if (data) {
          setIsModalOpen(false);
          openPopup(
            data.id,
            `Requesting a payment of ${orderData[currentOrder].price}`
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const openPopup = (orderId, description) => {
    console.log("Init popup");
    try {
      const options = {
        key: "rzp_live_H2Lapz9T21rqxK",
        order_id: orderId,
        name: "Integra",
        description: description,
        image: "http://localhost:3000/favicon.ico",
        handler: function (response) {
          if (response && response?.razorpay_payment_id) {
            const serviceID = "service_xz7gdnp";
            const templateID = "template_z0ykazj";
            emailjs
              .send(
                serviceID,
                templateID,
                {
                  plan_name: orderData[currentOrder].title,
                  link: orderData[currentOrder].link,
                  reply_to: "ranasatyamraj@gmail.com",
                  payment_id: response.razorpay_payment_id,
                  mail_to: mail,
                },
                {
                  publicKey: "KKTr-MDWoJ0FBdbRd",
                }
              )
              .then(
                () => {
                  setMail("");
                  setCurrentOrder(undefined);
                  setIsModalOpen(false);
                  console.log("Email sent!");
                },
                (err) => {
                  setIsModalOpen(false);
                  console.log(JSON.stringify(err));
                }
              );
            console.log("Subscription successful", response);
          }
        },
        prefill: {
          name: "Name",
          email: "example@example.com",
          contact: "0000000000",
        },
        theme: { color: "#ffffff" },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (err) {
      console.error("Error init popup");
      console.error(err);
    }
  };

  return (
    <div className="relative max-w-[100%] overflow-hidden">
      
      <div className="m-8 grid md:grid-cols-2 lg:grid-cols-3 gap-y-12 grid-cols-1 relative">
        
        {orderData.map((v, index) => {
          return (
            <div key={index}>
              <StorefrontCard
                key={index}
                {...v}
                onOrderClick={() => {
                  setCurrentOrder(index);
                  setIsModalOpen(true);
                }}
              />
            </div>
          );
        })}
        {isModalOpen && (
          <div className="fixed top-0 left-0 w-screen h-full" ref={modalRef}>
            <div className="backdrop-blur-md h-full w-full" />
            <div className="flex max-h-[80%] overflow-auto flex-col gap-4 max-w-[95%] items-start z-10 absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] bg-[#27272C] backdrop-blur-lg p-10 rounded-md">
              <button className="ml-auto" onClick={() => setIsModalOpen(false)}>
                <img className="h-10 w-10" src="/close.png" alt="Close" />
              </button>
              <form
                onSubmit={onOrder}
                className="flex flex-col gap-4 max-w-[95%] lg:w-[100%] items-start w-screen "
              >
                <div className="w-[100%] flex flex-col mb-10">
                  <div className="flex flex-row items-center">
                    <div className="flex flex-col flex-1 gap-4">
                      <span className="md:text-3xl text-2xl font-bold">
                        {orderData[currentOrder].title}
                      </span>
                    </div>
                    <span className="text-xl">
                      ₹{orderData[currentOrder].price}
                    </span>
                  </div>
                  <span
                    className="text-xl mt-10"
                    dangerouslySetInnerHTML={{
                      __html: orderData[currentOrder].description,
                    }}
                  ></span>
                  <span className="text-2xl font-semibold mr-auto text-gray-400 mt-10">
                    Features
                  </span>
                  <div className="flex flex-col flex-wrap w-full max-h-[250px]">
                    {orderData[currentOrder].features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </div>
                </div>
                <span className="text-xl font-semibold text-white mr-auto">
                  Email
                </span>
                <input
                  onChange={(e) => setMail(e.target.value)}
                  value={mail}
                  required
                  name="mail"
                  className="bg-black/30 rounded-md py-2 px-1 outline-none border-blue text-2xl w-full"
                />
                <button
                  className="bg-blue w-full text-xl rounded-md py-3"
                  type="submit"
                >
                  Order
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
      <div className="z-[-1] absolute top-[60%] translate-y-[-50%] left-[30%] blur-3xl">
        <img
          src="/background-shape.png"
          alt="Shape"
          className="opacity-30 h-[600px] w-[800px]"
        />
      </div>
      <div className="z-[-1] absolute top-[30%] translate-y-[-60%] rounded-full left-[-10%] blur-3xl">
        <img
          src="/background-blur.png"
          alt="Shape"
          className="opacity-40 h-[600px] w-[800px]"
        />
      </div>
    </div>
  );
};

export default IntegraShop;
