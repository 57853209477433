import React from 'react';
import Image from '../assets/img/how-it-works-img.png';

const HowItWorks = () => {
  return (
    <section className='section' data-aos='fade-up'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between'>

          {/* Text and Image Container */}
          <div className='flex flex-col lg:flex-row w-full'>
            {/* Text Content */}
            <div className='flex flex-col justify-center items-center flex-1 text-center space-y-6'>
              
              <div className='p-6 border-2 border-[#AC3CA5] rounded-lg'>
                <div className='text-2xl font-bold lg:text-lg lg:mb-2'>
                  Synapsed Infotech Shipping Policy
                </div>
                <div className='text-gray-700'>
                  At Synapsed Infotech Pvt Ltd, our products are digital goods. Upon purchase, customers receive their product instantly via email. There are no physical shipments. For any inquiries, please contact us at <span className='text-[#AB3BA3]'>synapsedinfotech@gmail.com.</span>
                </div>
              </div>

              <div className='p-6 border-2 border-[#AC3CA5] rounded-lg'>
                <div className='text-2xl font-bold lg:text-lg lg:mb-2'>Synapsed Infotech Terms and Conditions</div>
                <div className='text-gray-700 text-left'>
                  <p><strong>Introduction:</strong> Welcome to Synapsed Infotech Pvt Ltd. By accessing our services, you agree to comply with our terms and conditions. These terms govern your use of our digital products and services.</p>
                  <p><strong>Use of Services:</strong> Our digital products are intended for lawful use only. Unauthorized copying, distribution, or modification is prohibited.</p>
                  <p><strong>Payment and Delivery:</strong> Payments are processed securely, and products are delivered instantly via email upon purchase. Ensure your email address is accurate to receive your digital goods.</p>
                  <p><strong>Modifications:</strong> We reserve the right to modify these terms at any time. Changes will be communicated via email or our website.</p>
                </div>
              </div>

              <div className='p-6 border-2 border-[#AC3CA5] rounded-lg'>
                <div className='text-2xl font-bold lg:text-lg lg:mb-2'>Synapsed Infotech Privacy Policy</div>
                <div className='text-gray-700 text-left'>
                  <p><strong>Introduction:</strong> Synapsed Infotech Pvt Ltd is committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information.</p>
                  <p><strong>Information Collection:</strong> We collect personal information such as your name, email address, and payment details during the purchase process.</p>
                  <p><strong>Use of Information:</strong> Your information is used to process transactions, deliver digital products, and improve our services. We do not share your data with third parties.</p>
                  <p><strong>Data Security:</strong> We implement robust security measures to protect your information from unauthorized access and breaches.</p>
                </div>
              </div>

              <div className='p-6 border-2 border-[#AC3CA5] rounded-lg'>
                <div className='text-2xl font-bold lg:text-lg lg:mb-2'>Synapsed Infotech Cancellation Policy</div>
                <div className='text-gray-700 text-left'>
                  <p><strong>Introduction:</strong> At Synapsed Infotech Pvt Ltd, we prioritize customer satisfaction. However, due to the nature of digital goods, we maintain a strict cancellation policy.</p>
                  <p><strong>No Refunds:</strong> All sales are final. Once a digital product is purchased and delivered via email, refunds are not available. Please ensure you review your order carefully before completing your purchase.</p>
                </div>
              </div>
            </div>
            
            {/* Image Content */}
            <div className='flex items-center justify-center flex-1 p-6'>
              <img src={Image} alt='How It Works' className='max-w-full h-auto' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;

