import * as React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const StorefrontCard = ({ isEven, title, images, onOrderClick, price }) => {
  const items = images.map((img, index) => (
    <img
      className="h-full w-full aspect-square rounded-md"
      src={img}
      alt={title + index}
    />
  ));

  return (
    <div
      className={`flex flex-col gap-[30px] items-center justify-start relative transition-transform duration-300 z-0`}
    >
      <div
        className={`w-[350px] rounded-[10px] border-2 border-solid border-[#ac3ca5] px-[45px] py-[26px] gap-4 flex flex-col items-center cursor-pointer ${
          isEven ? "align-end" : "mt-auto"
        }`}
        onClick={() => onOrderClick(price)}
      >
        <span className="text-[40px] font-bold">{title}</span>
        <div className="flex-1 rounded-[28px] w-full relative">
          <AliceCarousel
            items={items}
            infinite
            autoPlayInterval={2000}
            autoPlay
            disableButtonsControls
            // disableDotsControls
          />
        </div>
        {/* <div className="text-[36px]">$949</div> */}
      </div>
      <button
        onClick={() => onOrderClick(price)}
        className="border-2 border-solid border-[#ac3ca5] rounded-full px-[40px] py-[20px]"
      >
        ₹{price}
      </button>
    </div>
  );
};

export default StorefrontCard;
