import React from 'react'

import Image from '../assets/img/s-img.png';


const AboutUs = () => {
  return (
    <section id='AboutUs' className='section' data-aos='fade-up'>
      <div className='container mx-auto'>

        <div className='flex flex-col gap-y-6 lg:flex-row lg:justify-between' style={{ border: '2px solid #AC3CA5', padding: '45px', borderRadius: '10px', background: 'rgba(255, 255, 255, 0.14)' }}>
          {/* item */}

          <div className='flex items-center gap-x-6' style={{ textAlign: 'center' }}>

            <div>
              <br></br>
              <h1
                className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
                data-aos=''
                data-aos-delay='500'
              >
                About Us
              </h1>

              <div className='text-gray' style={{ padding: '25px' }}>Synapsed Infotech Pvt Ltd is a technology company that specializes in developing infrastructure in two key areas - Machine learning and web3.
                The company offers a range of APIs for businesses and customers, including scraping data from various sources. They also create machine learning models that predict asset prices based on historical data, with an API to access the predicted data.
                Synapsed Infotech Pvt Ltd provides infrastructure for web3-related freelance projects, such as developing web3-ready front-ends and full-stack applications, ERC20 and ERC721 smart contracts, and more. Additionally, they make it easy for peers to connect to the blockchain without any hassle in the traditional way.
                Overall, Synapsed Infotech Pvt Ltd is dedicated to providing cutting-edge technology solutions that help businesses and individuals harness the power of machine learning and web3. </div>
              <br></br>
              <h1
                className='text-[32px] lg:text-[32px] font-bold leading-tight mb-6'
                data-aos=''
                data-aos-delay='500'
              >
                Meet The Team
              </h1>

              <center>   <img src={Image} alt='' style={{ width: '', textAlign: 'center', justifyItems: 'center' }} />
              </center>

              <br></br>


              <br></br>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
                Jahan Shah
              </div>
              <div className='text-2xl font-bold lg:text-[20px] lg:mb-2'>
                Founder and Director
              </div>
              <br></br>
            </div>
          </div>
          {/* item */}



        </div>



      </div>
    </section>
  )
}

export default AboutUs
