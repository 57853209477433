import * as React from "react";
import StorefrontCard from "./Cards/StorefrontCards";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useViewport from "./useViewport";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

const StorefrontCarousel = () => {
  let sliderRef = React.useRef(null);
  const modalRef = React.useRef(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [currentOrder, setCurrentOrder] = React.useState(0);
  const [mail, setMail] = React.useState("");
  const { width } = useViewport();
  const navigate = useNavigate();

  const slidesToShow = width > 1222 ? 3 : width > 800 ? 2 : 1;

  React.useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollIntoView();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalRef.current, isModalOpen]);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const orderData = [
    {
      title: "Apollo 10k leads",
      images: ["/black_pink_db.png", "/apollo_10k.png"],
      price: 4999,
      link: "https://drive.google.com/file/d/1lYUmOhclW2YCooaxAVsu8asNbL8LhB3p/view?usp=drive_link",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro corporis vitae repellat eum. Officia culpa eum labore ullam ex! Delectus nisi dolores ipsa optio sed dolorem iusto nulla dolore facere!",
      features: [
        "IT Industry Contacts",
        "Fashion Sector Leads",
        "Comprehensive Data",
        "Multi-Domain Coverage",
        "Business Profiles",
        "Contact Details",
        "Industry Insights",
        "Geographic Distribution",
        "Company Size",
        "Sector Analysis",
      ],
    },
    {
      title: "Comprehensive healthcare",
      images: ["/comprehensive_health.png", "/comprehensive_health_sheet.png"],
      price: 2999,
      link: "https://drive.google.com/drive/folders/1UgyIHnnhiHP3Mw5LJ1z_iwTkAE8h38um?usp=drive_link",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus dolores fuga modi. Quibusdam numquam placeat minima perferendis magnam a debitis, praesentium laboriosam dolorem consectetur ipsum voluptatibus iure illo dicta alias?",
      features: [
        "Hospitals Listing",
        "Nurse Registries",
        "Clinics Index",
        "Medical Facilities",
        "Specialty Services",
        "Healthcare Contacts",
        "Regional Coverage",
        "Service Categories",
        "Facility Ratings",
        "Operational Details",
      ],
    },
    {
      title: "Comprehensive B2B",
      images: ["/comprehensive_b2b.png", "/comprehensive_b2b_sheet.png"],
      price: 5999,
      link: "https://drive.google.com/drive/folders/1bhN6MUBe1iNBaIGufNGdY0PkIy4VkGmU?usp=drive_link",
      description:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nam quas reprehenderit molestias! Quibusdam maxime dolorem ea possimus eveniet voluptas repellendus quae ex doloribus necessitatibus nihil sint, repudiandae impedit harum ullam.",
      features: [
        "Company Directories",
        "Industry Categories",
        "City-Specific Listings",
        "Financial Summaries",
        "Corporate Contacts",
        "Manufacturing Index",
        "Textile Businesses",
        "Jewellery Crafters",
        "Grocery Markets",
        "Wholesale Traders",
        "Plastic Industry",
        "Business Rankings",
        "SME Overviews",
        "Trade Volumes",
        "Market Analysis",
        "B2B Networks",
        "Corporate Profiles",
      ],
    },
    {
      title: "Student Database",
      images: ["/extensive_student.png", "student_db_sheet.png"],
      price: 3999,
      link: "https://drive.google.com/drive/folders/1wapk3_o27b4CGazFhJ8xtOe7k65gfjSg?usp=drive_link",
      description:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat esse quo doloremque quaerat maxime ducimus reiciendis optio aliquam ratione dignissimos. Ipsum quis tenetur tempora molestiae natus accusantium rem error ab!",
      features: [
        "Yearly Enrollments",
        "State-Wise Data",
        "Major/Minor Specializations",
        "University Profiles",
        "Admission Trends",
        "Graduation Outcomes",
        "Scholarship Details",
        "Performance Metrics",
        "Extracurriculars",
        "Demographic Insights",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    appendDots: () => <div />,
    nextArrow: <div />,
    prevArrow: <div />,
  };

  const onOrder = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://synapsed.onrender.com/create-order",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: orderData[currentOrder].price,
            currency: "INR",
          }),
        }
      );

      if (response.status !== 500) {
        const data = await response.json();
        if (data) {
          setIsModalOpen(false);
          openPopup(
            data.id,
            `Requesting a payment of ${orderData[currentOrder].price}`
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const openPopup = (orderId, description) => {
    console.log(orderId);
    console.log("Init popup");
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        order_id: orderId,
        name: "Integra",
        description: description,
        image: "http://localhost:3000/favicon.ico",
        handler: function (response) {
          if (response && response?.razorpay_payment_id) {
            const serviceID = process.env.REACT_APP_SERVICE_ID;
            const templateID = process.env.REACT_APP_TEMPLATE_ID;
            emailjs
              .send(
                serviceID,
                templateID,
                {
                  plan_name: orderData[currentOrder].title,
                  link: orderData[currentOrder].link,
                  reply_to: "ranasatyamraj@gmail.com",
                  payment_id: response.razorpay_payment_id,
                  mail_to: mail,
                },
                {
                  publicKey: "KKTr-MDWoJ0FBdbRd",
                }
              )
              .then(
                () => {
                  setMail("");
                  // setCurrentOrder(undefined);
                  console.log("Email sent!");
                },
                (err) => {
                  console.log(JSON.stringify(err));
                }
              );
            console.log("Subscription successful", response);
          }
        },
        prefill: {
          name: "Name",
          email: "example@example.com",
          contact: "0000000000",
        },
        theme: { color: "#ffffff" },
      };

      console.log(options);

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (err) {
      console.error("Error init popup");
      console.error(err);
    }
  };

  return (
    <section
      className="sm:px-0 px-4 py-8 lg:py-16 ml-auto mr-auto section flex flex-row items-center overflow-clip relative z-[1]"
      data-aos="fade-up"
      data-aos-delay=""
    >
      {/* <div className="absolute w-0 left-0 backdrop-blur-[3px] h-full z-[10] md:w-[30%]" /> */}
      <div className="flex flex-col w-full items-center z-2">
        <div className="w-screen sm:h-[700px] h-[600px] flex flex-col gap-12 container">
          <button
            className="bg-blue px-10 py-5 rounded-full ml-auto"
            onClick={() => navigate("/shop")}
          >
            View shop
          </button>
          <Slider
            {...sliderSettings}
            adaptiveHeight
            autoplay
            ref={(slider) => {
              sliderRef = slider;
            }}
            arrows={false}
            infinite
            pauseOnHover
          >
            {orderData.map((v, index) => {
              return (
                <div key={index}>
                  <StorefrontCard
                    key={index}
                    {...v}
                    onOrderClick={() => {
                      setIsModalOpen(true);
                      setCurrentOrder(index);
                    }}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="w-[280px] flex flex-row justify-between mt-[70px] h-14">
          <img
            src="/arrow_left.png"
            alt="Arrow left"
            className="w-[60px] aspect-square cursor-pointer sm:flex hidden"
            onClick={previous}
          />
          <img
            src="/arrow_right.png"
            alt="Arrow right"
            className="w-[60px] aspect-square cursor-pointer sm:flex hidden"
            onClick={next}
          />
        </div>
      </div>
      {/* <div className="absolute w-0 right-0 backdrop-blur-[3px] h-full z-[10] md:w-[30%]" /> */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 w-screen h-full" ref={modalRef}>
          <div className="backdrop-blur-md h-full w-full" />
          <div className="flex max-h-[80%] overflow-auto flex-col gap-4 max-w-[95%] items-start z-10 absolute left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] bg-[#27272C] backdrop-blur-lg p-10 rounded-md">
            <button className="ml-auto" onClick={() => setIsModalOpen(false)}>
              <img className="h-10 w-10" src="/close.png" alt="Close" />
            </button>
            <form
              onSubmit={onOrder}
              className="flex flex-col gap-4 max-w-[95%] lg:w-[100%] items-start w-screen "
            >
              <div className="w-[100%] flex flex-col mb-10">
                <div className="flex flex-row items-center">
                  <div className="flex flex-col flex-1 gap-4">
                    <span className="md:text-3xl text-2xl font-bold">
                      {orderData[currentOrder].title}
                    </span>
                  </div>
                  <span className="text-xl">
                  ₹{orderData[currentOrder].price}
                  </span>
                </div>
                <span className="text-xl mt-10">
                  {orderData[currentOrder].description}
                </span>
                <span className="text-2xl font-semibold mr-auto text-gray-400 mt-10">
                  Features
                </span>
                <div className="flex flex-col flex-wrap w-full max-h-[250px]">
                  {orderData[currentOrder].features.map((feature) => (
                    <li>{feature}</li>
                  ))}
                </div>
              </div>
              <span className="text-xl font-semibold text-white  mr-auto">
                Email
              </span>
              <input
                onChange={(e) => setMail(e.target.value)}
                value={mail}
                required
                name="mail"
                className="bg-black/30 rounded-md py-2 px-1 outline-none border-blue text-2xl w-full"
              />
              <button
                className="bg-blue w-full text-xl rounded-md py-3"
                type="submit"
              >
                Order
              </button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default StorefrontCarousel;
