// import images

export const navData = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Shop",
    href: "/shop",
  },
  {
    name: "Integra",
    href: "#Integra",
  },
  {
    name: "Web3",
    href: "#Web3",
  },
  {
    name: "About Us",
    href: "#AboutUs",
  },
];
