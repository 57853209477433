import React from 'react';

// import components
import Logo from '../assets/img/synapsed-logo.png';
import Nav from './Nav';


// import icons
import { CgMenuRight } from 'react-icons/cg';

const Header = ({ setNavMobile }) => {
  return (
    <header
      className='py-[30px] lg:pt-[10px]'
      data-aos='fade-down'
      data-aos-delay='900'
      data-aos-duration='2000'
    >
      <div className='container mx-auto flex items-center justify-between'>
        {/* logo */}
        <a href='/'>
          <img src={Logo} alt=''  style={{width:'23%'}}/>
        </a>
        {/* nav & btns */}
        <div className='hidden lg:flex gap-x-[55px]'>
          <Nav />
          {/* nav & btns 
        <AccountBtns />   */}
        </div>
        {/* open nav btn */}
        <div
          onClick={() => setNavMobile(true)}
          className='lg:hidden cursor-pointer'
        >
          <CgMenuRight className='text-2xl' />
        </div>
      </div>
    </header>
  );
};

export default Header;
