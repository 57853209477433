import * as React from "react";

const useViewport = () => {
  const [size, setSize] = React.useState({
    height: 0,
    width: 0,
  });

  React.useEffect(() => {
    const handleResize = () => {
      setSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
};

export default useViewport;
