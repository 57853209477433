import * as React from "react";
import CheckBox from "./CheckBox";
import IntegraPlanTile from "./IntegraPlanTile";
import { IntegraPlanData } from "../assets/integra-plan";
import Number from "../assets/img/2.png";

const Integra = () => {
  const [selectedPlan, setSelectedPlan] = React.useState(0);
  const [isYearly, setIsYearly] = React.useState(false);

  const getStarted = async () => {
    console.log(selectedPlan);
    const { description } = IntegraPlanData.plans[selectedPlan];
    const id = isYearly
      ? IntegraPlanData.plans[selectedPlan].pricing.yearly.plan_id
      : IntegraPlanData.plans[selectedPlan].pricing.monthly.plan_id;
    console.log(id);
    console.log("Init Sub");
    const response = await fetch(
      "https://synapsed.onrender.com/create-subscription",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
        }),
      }
    );

    const data = await response.json();
    if (data && data.data.id) {
      console.log(data);
      openPopup(data.data.id, description);
    } else {
      console.log(data);
      alert("Failed to fetch or create plan.");
    }
  };

  const openPopup = (subId, description) => {
    console.log("Init popup");
    console.log(subId);
    try {
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        subscription_id: subId,
        name: "Integra",
        description: description,
        image: "http://localhost:3000/favicon.ico",
        handler: function (response) {
          console.log("Subscription successful", response);
          alert(
            `Subscription successful! Payment ID: ${response.razorpay_payment_id}`
          );
        },
        prefill: {
          name: "Name",
          email: "example@example.com",
          contact: "0000000000",
        },
        theme: { color: "#ffffff" },
      };

      const rzp = new window.Razorpay(options);

      rzp.open();
    } catch (err) {
      console.error("Error init popup");
      console.error(err);
    }
  };

  return (
    <section
      className="container w-full sm:px-0 px-4 py-8 lg:py-16 ml-auto mr-auto section"
      data-aos="fade-up"
    >
      <div className="w-full rounded-[10px] border-2 border-solid border-[#ac3ca5] flex md:flex-row flex-col px-[20px] py-[25px] sm:px-[40px] sm:py[50px] items-center">
        <div className="flex flex-col gap-12 flex-[0.6] items-center">
          <span className="text-[#ac3ca5] font-bold sm:text-[40px] text-[25px] mr-auto">
            Empowering Your Outreach
          </span>
          <span className="max-w-[400px] mr-auto">
            Effortlessly connect with top leads.Our AI tool curates the best
            leads for your business, making growth simple and effective.
          </span>
          <div className="md:grid hidden grid-cols-2 w-full">
            {IntegraPlanData.plans[selectedPlan].features.map((val, index) => (
              <CheckBox title={val} key={index} checked />
            ))}
          </div>
          <button
            className={`h-[64px] w-[170px] border-2 border-solid border-[#ac3ca5] rounded-[100px] bg-[#ac3ca5]`}
            onClick={getStarted}
          >
            Get Started
          </button>
        </div>
        <div className="flex flex-col flex-[0.4] items-end gap-[30px]">
          <div className="flex flex-row gap-[15px] w-full md:justify-end justify-center md:pt-0 pt-4">
            <button
              onClick={() => setIsYearly(false)}
              className={`h-[50px] w-[120px] border-2 border-solid border-[#ac3ca5] rounded-[100px] ${
                !isYearly ? "bg-[#ac3ca5]" : "bg-transparent"
              }`}
            >
              Monthly
            </button>
            <button
              onClick={() => setIsYearly(true)}
              className={`h-[50px] w-[120px] border-2 border-solid border-[#ac3ca5] rounded-[100px] ${
                isYearly ? "bg-[#ac3ca5]" : "bg-transparent"
              }`}
            >
              Yearly
            </button>
          </div>
          {IntegraPlanData.plans.map((val, index) => (
            <IntegraPlanTile
              price={
                val.pricing.monthly
                  ? !isYearly
                    ? val.pricing.monthly.price
                    : val.pricing.yearly.price
                  : val.pricing.yearly.price
              }
              title={val.name}
              selected={selectedPlan === index}
              key={index}
              onClick={() => setSelectedPlan(index)}
            />
          ))}
        </div>
      </div>
      <center>
        <img src={Number} alt="" data-aos="fade-up" data-aos-delay="" />
      </center>
    </section>
  );
};

export default Integra;
