import React from "react";

// import images
import Image from "../assets/img/glob.png";
import Number from "../assets/img/1.png";

// import icons
import { IoIosArrowDroprightCircle } from "react-icons/io";

const Hero = () => {
  return (
    <section className="overflow-hidden">
      <div className="container mx-auto">
        <div className="flex flex-col items-center lg:flex-row">
          {/* hero text */}
          <div className="flex-1">
            {/* badge text 
          
            {/* title */}
            <h1
              className="text-[32px] lg:text-[48px] font-bold leading-tight mb-6"
              data-aos="fade-down"
              data-aos-delay="500"
            >
              Building the future with Machine Learning and{" "}
              <span
                style={{
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  textFillColor: "transparent",
                  WebkitTextFillColor: "transparent",
                  backgroundImage: "linear-gradient(45deg, #960EE9, #9935A9)",
                }}
              >
                Web3 technology.
              </span>
            </h1>
            <p
              className="max-w-[540px] leading-relaxed mb-8"
              data-aos="fade-down"
              data-aos-delay=""
            >
              Innovating with Machine Learning and Web3 Technology. Harnessing
              the power of these technologies, we offer efficient and reliable
              infrastructure solutions for businesses and individuals alike.
            </p>
            <a href="#AboutUs">
              {" "}
              <button
                section
                className="btn gap-x-6 pl-6 text-sm lg:h-16 lg:text-base"
                data-aos=""
                data-aos-delay="700"
              >
                About Us
                <IoIosArrowDroprightCircle className="text-2xl lg:text-3xl" />
              </button>{" "}
            </a>
          </div>
          {/* hero image */}
          <div className="">
            <img
              src={Image}
              alt=""
              data-aos="fade-up"
              data-aos-delay="600"
              style={{
                transformStyle: "preserve-3d",
                transform: "rotateX(0deg) rotateY(90deg)",
                animation: "rotate 30s linear infinite",
              }}
            />
          </div>
        </div>
        <center>
          {" "}
          <img src={Number} alt="" data-aos="fade-up" data-aos-delay="" />
        </center>
      </div>
    </section>
  );
};

export default Hero;
