import React from "react";

const Web3 = () => {
  return (
    <section id="Web3" className="section">
      <div className="container mx-auto">
        <div className="flex flex-col items-center lg:flex-row">
          {/* hero text */}
          <div className="flex-1">
            {/* badge text 
          
            {/* title */}
            <h1
              className="text-[32px] lg:text-[48px] font-bold leading-tight mb-6"
              data-aos="fade-down"
              data-aos-delay=""
            >
              Web3
            </h1>
            <p
              className="leading-relaxed mb-8"
              data-aos="fade-down"
              data-aos-delay=""
            >
              We're gearing up to introduce a range of services, including
              ZKproof systems, smart contract development, and user-friendly
              blockchain connectivity for everyone. Stay tuned as we continue to
              make blockchain technology accessible, easy, and intuitive for
              all. The future of Web3 is here
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Web3;
