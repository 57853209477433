import * as React from 'react'

const CheckBox = ({ checked, title }) => {

    return (
        <label className='flex flex-row w-full gap-4 appearance-none align-middle outline-none items-center'>
            <img src={checked ? "/Checked.png" : "/UnChecked.png"} alt="Checkbox" c className='h-10 w-10' />
            <span className='text-[14px]'>
                {title}
            </span>
        </label>
    )
}

export default CheckBox