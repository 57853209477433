import * as React from "react";

const IntegraPlanTile = ({ title, price, selected, onClick }) => {
  return (
    <div
      className={`flex flex-row justify-between items-center h-[100px] rounded-[20px] w-full ${
        selected ? "border-[#Ac3ca5]" : "border-white"
      } border-2 border-solid px-[20px] py-[35px] max-w-[350px] cursor-pointer`}
      onClick={onClick}
    >
      <span className="text-[20px] text-[#AC3CA5] font-extrabold">{title}</span>
      <div>
        <span className="text-[35px]">{price}</span>
        <span className="text-[10px]">/{"mo"}</span>
      </div>
    </div>
  );
};

export default IntegraPlanTile;
