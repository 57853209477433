import * as React from "react";

const IntegraInformation = () => {
  return (
    <section
      id="Integra"
      className="container w-full sm:px-0 px-4 py-12 lg:py-24 ml-auto mr-auto section flex flex-col gap-10"
      data-aos="fade-up"
      data-aos-delay=""
    >
      <span className="text-[#ac3ca5] font-bold sm:text-[40px] text-[25px] mr-auto">
        Integra
      </span>
      <div className="w-full rounded-[10px] border-2 border-solid border-[#ac3ca5] flex gap-10 flex-col px-[20px] py-[25px] sm:px-[40px] sm:py[50px] items-center">
        <div className="flex flex-col">
          <span className="text-white font-bold sm:text-[30px] text-[15px] mr-auto">
            What is integra?
          </span>
          <span>
            Integra is not just a tool; it's your partner in maximizing business
            outreach. Leveraging in-house advance AI algorithms, Integra curates
            a personalised list of your leads along with contact details and
            mass emailing support directly through the platform, and the beauty
            of it is that the emails will also be AI generated so you can be
            focused on your business and let Integra handle the lead generation
            process!
          </span>
        </div>
        <div className="flex flex-col w-full">
          <span className="text-white font-bold sm:text-[30px] text-[15px] mr-auto">
            Key Features:
          </span>
          <li>
            AI-Enhanced Lead Curation: Receive highly targeted leads suited to
            your specific business needs.
          </li>
          <li>
            Efficient Integration: Download a CSV of your selected leads and
            export it to your CRM!
          </li>
          <li>
            Intuitive Interface: Easy-to-use, chat-like interface for
            straightforward lead generation.
          </li>
          <li>
            Event Insights: Keeps you informed about relevant industry events,
            maximizing networking opportunities.
          </li>
          <li>
            Team collaboration: invite your team members to your team for
            handpicking leads from the curated list
          </li>
          <li>
            AI Emailing: Mass email your target audience by just a click of
            button, send mass emails which are generated by AI
          </li>
        </div>
      </div>
    </section>
  );
};

export default IntegraInformation;
