export const IntegraPlanData = {
  "plans": [
    {
      "name": "Growth",
      "description": "Best for small business owners who need leads for business.",
      "features": [
        "2000 Active Leads",
        "40 Credits",
        "10,000 Emails Monthly",
        "Leads Collection",
        "CSV export",
        "Team Collaboration (max 2 members)",
        "Up to 2 Teams",
        "Support"
      ],
      "pricing": {
        "monthly": { "price": "₹999", "plan_id": "plan_NnYb2tVbJPiCjI" },
        "yearly": { "price": "₹833", "plan_id": "plan_NS1wFMl9X4TPBa" }
      }
    },
    {
      "name": "Super Growth",
      "description": "Best for medium level business owners who need leads for business.",
      "features": [
        "50,000 Active Leads",
        "1,000 Credits",
        "250,000 Emails Monthly",
        "Leads Collection",
        "CSV export",
        "Team Collaboration (max 4 members)",
        "Up to 4 Teams",
        "Priority Support",
        "A/Z Testing"
      ],
      "pricing": {
        "monthly": { "price": "₹2499", "plan_id": "plan_NnYc8miwtDhtig" },
        "yearly": { price: "₹2083", "plan_id": "plan_NS367e0K0MXCAO" }
      }
    },
    {
      "name": "Hyper Growth",
      "description": "Best for big business owners who need leads for business.",
      "features": [
        "100,000 Active Leads",
        "2,000 Credits",
        "500,000 Emails Monthly",
        "Leads Collection",
        "CSV export",
        "Team Collaboration (max 6 members)",
        "Up to 6 Teams",
        "Live Chat Support",
        "A/Z Testing"
      ],
      "pricing": {
        "monthly": { "price": "₹3999", "plan_id": "plan_NnYcVg36tTdTEq" },
        "yearly": { "price": "₹3333", "plan_id": "plan_NS36iylJ6Ns1AL" }
      }
    },
    {
      "name": "Life Time Deal",
      "description": "Best for big business owners who need leads for business.",
      "features": [
        "2,500,000 Active Leads",
        "5,000 Credits",
        "500,000 Emails Monthly",
        "Leads Collection",
        "CSV export",
        "Team Collaboration (max 6 members)",
        "Up to 6 Teams",
        "Live Chat Support",
        "A/Z Testing"
      ],
      "pricing": {
        "yearly": { "price": "₹9999", "plan_id": "plan_NUOHLbREPliIH7" }
      }
    }
  ]
}
