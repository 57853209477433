import React from 'react';

// import images
import Logo from '../assets/img/logo-12.png';


// import icons
import {

  IoLogoInstagram,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoFacebook,
 
  IoMdMail,
  IoIosPin,
  IoMdCall,
} from 'react-icons/io';

const Footer = () => {
  return (
    <footer className='lg:pt-24 pt-0' data-aos='fade-up'>
      <div className='container mx-auto lg:mb-24'>
        <div className='flex flex-col gap-12 lg:flex-row'>
          {/* logo */}
          <div className='flex-1 mx-auto lg:mx-0 mb-7 max-w-[485px]'>
            <a href='#'>
              <img src={Logo} alt='' />
            </a>
            <br></br>
            <div className='text-gray' style={{}}>Synapsed Infotech Pvt Ltd is a technology company that specializes in developing infrastructure in two key areas - Machine learning and web3  </div>
           <br></br>
            <div className='flex text-2xl gap-x-8'>

            <a className='hover:text-blue transition' href='https://www.facebook.com/'>

              <IoLogoFacebook /> 
            </a>
            <a className='hover:text-blue transition' href='#'>
              <IoLogoTwitter />
            </a>
            <a className='hover:text-blue transition' href='#'>
              <IoLogoLinkedin />
            </a>
            <a className='hover:text-blue transition' href='#'>
              <IoLogoInstagram />
            </a>
           
            
          </div>
          
          
          </div>
          {/* link group */}
          <div className='flex flex-1 flex-col gap-16 lg:flex-row'>
            {/* link group */}
            <div className='text-center w-full lg:text-left'>
              <div className='text-xl font-medium mb-5'>Pages</div>
              <ul className='space-y-4 text-gray'>
                <li>
                  <a className='hover:text-blue transition' href='/'>
                    Home
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#ApiGardens'>
                  Api Gardens
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#Platform'>
                  Platform
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#Web3'>
                  Web3
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#AboutUs'>
                  About us
                  </a>
                </li>
              </ul>
            </div>
            {/* link group 
            <div className='text-center w-full lg:text-left'>
              <div className='text-xl font-medium mb-6'>Service</div>
              <ul className='space-y-4 text-gray'>
                <li>
                  <a className='hover:text-blue transition' href='#'>
                  Designers
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#'>
                  Developer
                  </a>
                </li>
                <li>
                  <a className='hover:text-blue transition' href='#'>
                  Freelancers
                  </a>
                </li>
                
                
              </ul>
            </div> */}
{/* link group */}
<div className='text-center w-full lg:text-left'>
              <div className='text-xl font-medium mb-6'>Contact</div>
              <ul className='space-y-4 text-gray lg:flex-row'>
<li>
<a className='hover:text-blue transition' href='tel:+91 7487888299'>

              <div className='flex items-center gap-6'>
              
                    <IoMdCall /> +91 7487888299
                    
               
              </div>
              </a>
              </li>
              <li>
<a className='hover:text-blue transition' href='mailto:synapsedinfotech@gmail.com'>

              <div className='flex items-center gap-6'>
              
                    <IoMdMail /> synapsedinfotech@gmail.com
                    
               
              </div>
              </a>
              </li>
              {/*    <li>
<a className='hover:text-blue transition' href='#'>

              <div className='flex items-center gap-6'>
              
                    <IoIosPin /> avcdfg  vghahshj 
                    
               
              </div>
              </a>
  </li>  */}
              
                
              </ul>
            </div>

          </div>
         

        </div>
      </div>
      {/* copy & social */}
      <div className='py-12'>
        <div className='container mx-auto flex flex-col items-center gap-y-6 lg:flex-row lg:justify-between lg:gap-y-0'>
          {/* copy text */}
          <div>&copy; 2023 Synapsed Infotech Pvt Ltd. All rights reserved.</div>
         
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
